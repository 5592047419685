import {
  Module,
  VuexModule,
  MutationAction,
  Action,
} from 'vuex-module-decorators'
import { StatisticInfoType } from '@/types/statistic'
import { statisticAPI } from '@/api/statistic.api'

@Module({ stateFactory: true, namespaced: true })
export default class StatisticModule extends VuexModule {
  _statistic: StatisticInfoType | Record<string, any> = {}

  get statisticInfo() {
    return this._statistic
  }

  @MutationAction({
    mutate: ['_statistic'],
  })
  async getStatisticInfo() {
    const [error, data] = await statisticAPI.statisticInfo()

    if (!error && data) {
      return {
        _statistic: data,
      }
    } else {
      return {
        _statistic: [],
      }
    }
  }
}
