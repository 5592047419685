import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { AxiosService } from '@/plugins/axios'

type FileAxiosResponse<T> = [null, AxiosResponse<T>] | [AxiosError<T>]

export default class FilesApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/file'
          : '/api/file',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<FileAxiosResponse<T>> {
    try {
      const response = await this.axiosInstance.request<T>(config)
      return [null, response]
    } catch (error: any) {
      return [error]
    }
  }

  async filesUpload(file: File): Promise<FileAxiosResponse<string>> {
    const form = new FormData()
    form.append('file', file)

    return await this.axiosCall<string>({
      method: 'post',
      url: '/upload/',
      data: form,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async download(id: string): Promise<FileAxiosResponse<ArrayBuffer>> {
    return await this.axiosCall<ArrayBuffer>({
      method: 'get',
      url: `/downloadId/${id}`,
      responseType: 'arraybuffer',
    })
  }

  async downloadExample(): Promise<FileAxiosResponse<ArrayBuffer>> {
    return await this.axiosCall<ArrayBuffer>({
      method: 'get',
      url: '/downloadExample',
      responseType: 'arraybuffer',
    })
  }
}

export const filesAPI = new FilesApiService()
