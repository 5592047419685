import { Module, VuexModule, MutationAction } from 'vuex-module-decorators'
import { PayloadListType, TableExportType } from '@/types/general'
import { OrdersListItemType } from '@/types/orders'
import { ordersAPI } from '@/api/orders.api'

@Module({ stateFactory: true, namespaced: true })
export default class OrdersModule extends VuexModule {
  _list: OrdersListItemType[] = []
  _total = 0
  _selected = 0
  _detail: OrdersListItemType | Record<string, any> = {}
  _exportOrders: TableExportType | Record<string, any> = {}

  get ordersList() {
    return this._list
  }

  get ordersTotal() {
    return this._total
  }

  get selectedOrders() {
    return this._selected
  }

  get ordersDetail() {
    return this._detail
  }

  get exportOrders() {
    return this._exportOrders
  }

  @MutationAction({
    mutate: ['_list', '_total', '_selected'],
  })
  async getOrdersList(payload: PayloadListType) {
    const [error, data] = await ordersAPI.ordersList(payload)

    if (!error && data) {
      return {
        _list: data.items,
        _total: data.count,
        _selected: data.SELECTED,
      }
    } else {
      return {
        _list: [],
        _total: 0,
        _selected: null,
      }
    }
  }

  @MutationAction({
    mutate: ['_detail'],
  })
  async getOrdersDetail(id: number) {
    const [error, data] = await ordersAPI.ordersDetail(id)

    if (!error && data) {
      return {
        _detail: data,
      }
    } else {
      return {
        _detail: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_exportOrders'],
  })
  async getExportOrders(payload: PayloadListType) {
    const [error, data] = await ordersAPI.ordersExport(payload)

    if (!error && data) {
      return {
        _exportOrders: data,
      }
    } else {
      return {
        _exportOrders: {},
      }
    }
  }
}
