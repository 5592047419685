import {
  Module,
  VuexModule,
  MutationAction,
  Action,
} from 'vuex-module-decorators'
import { PayloadListType, TableExportType } from '@/types/general'
import { transactionsAPI } from '@/api/transactions.api'
import {
  ActivationsListItemType,
  reportsCreateType,
  ReportsListItemType,
  UsersListItemType,
  VendorsListItemType,
} from '@/types/transactions'

@Module({ stateFactory: true, namespaced: true })
export default class TransactionsModule extends VuexModule {
  _vendors: VendorsListItemType[] = []
  _vendorsTotal = 0
  _vendorsDetail: VendorsListItemType | Record<string, any> = {}
  _vendorsExport: TableExportType | Record<string, any> = {}

  _users: UsersListItemType[] = []
  _usersTotal = 0
  _usersDetail: UsersListItemType | Record<string, any> = {}
  _usersExport: TableExportType | Record<string, any> = {}

  _activations: ActivationsListItemType[] = []
  _activationsTotal = 0
  _activationsDetail: ActivationsListItemType | Record<string, any> = {}
  _activationsExport: TableExportType | Record<string, any> = {}

  _reports: ReportsListItemType[] = []
  _reportsTotal = 0
  _reportsDetail: ReportsListItemType | Record<string, any> = {}
  _reportsExport: TableExportType | Record<string, any> = {}

  get vendorsList() {
    return this._vendors
  }

  get vendorsTotal() {
    return this._vendorsTotal
  }

  get vendorsExport() {
    return this._vendorsExport
  }

  get usersList() {
    return this._users
  }

  get usersTotal() {
    return this._usersTotal
  }

  get usersExport() {
    return this._usersExport
  }

  get activationsList() {
    return this._activations
  }

  get activationsTotal() {
    return this._activationsTotal
  }

  get activationsExport() {
    return this._activationsExport
  }

  get reportsList() {
    return this._reports
  }

  get reportsTotal() {
    return this._reportsTotal
  }

  get reportsExport() {
    return this._reportsExport
  }

  @MutationAction({
    mutate: ['_vendors', '_vendorsTotal'],
  })
  async getVendorsList(payload: PayloadListType) {
    const [error, data] = await transactionsAPI.vendorsList(payload)

    if (!error && data) {
      return {
        _vendors: data.items,
        _vendorsTotal: data.count,
      }
    } else {
      return {
        _vendors: [],
        _vendorsTotal: 0,
      }
    }
  }

  @MutationAction({
    mutate: ['_vendorsExport'],
  })
  async getExportVendors(payload: PayloadListType) {
    const [error, data] = await transactionsAPI.vendorsExport(payload)

    if (!error && data) {
      return {
        _vendorsExport: data,
      }
    } else {
      return {
        _vendorsExport: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_users', '_usersTotal'],
  })
  async getUsersList(payload: PayloadListType) {
    const [error, data] = await transactionsAPI.usersList(payload)

    if (!error && data) {
      return {
        _users: data.items,
        _usersTotal: data.count,
      }
    } else {
      return {
        _users: [],
        _usersTotal: 0,
      }
    }
  }

  @MutationAction({
    mutate: ['_usersExport'],
  })
  async getExportUsers(payload: PayloadListType) {
    const [error, data] = await transactionsAPI.usersExport(payload)

    if (!error && data) {
      return {
        _usersExport: data,
      }
    } else {
      return {
        _usersExport: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_activations', '_activationsTotal'],
  })
  async getActivationsList(payload: PayloadListType) {
    const [error, data] = await transactionsAPI.activationsList(payload)

    if (!error && data) {
      return {
        _activations: data.items,
        _activationsTotal: data.count,
      }
    } else {
      return {
        _activations: [],
        _activationsTotal: 0,
      }
    }
  }

  @MutationAction({
    mutate: ['_activationsExport'],
  })
  async getExportActivations(payload: PayloadListType) {
    const [error, data] = await transactionsAPI.activationsExport(payload)

    if (!error && data) {
      return {
        _activationsExport: data,
      }
    } else {
      return {
        _activationsExport: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_reports', '_reportsTotal'],
  })
  async getReportsList(payload: PayloadListType) {
    const [error, data] = await transactionsAPI.reportsList(payload)

    if (!error && data) {
      return {
        _reports: data.items,
        _reportsTotal: data.count,
      }
    } else {
      return {
        _reports: [],
        _reportsTotal: 0,
      }
    }
  }

  @MutationAction({
    mutate: ['_reportsExport'],
  })
  async getExportReports(payload: PayloadListType) {
    const [error, data] = await transactionsAPI.reportsExport(payload)

    if (!error && data) {
      return {
        _reportsExport: data,
      }
    } else {
      return {
        _reportsExport: {},
      }
    }
  }

  @Action
  async reportsCreate(data: reportsCreateType) {
    await transactionsAPI.reportsCreate(data)
  }
}
