import {
  Module,
  VuexModule,
  MutationAction,
  Action,
} from 'vuex-module-decorators'
import { PayloadListType, TableExportType } from '@/types/general'
import {
  OrderReportsCreateType,
  OrderReportsListItemType,
} from '@/types/orderReports'
import { orderReportsAPI } from '@/api/orderReports.api'

@Module({ stateFactory: true, namespaced: true })
export default class OrderReportsModule extends VuexModule {
  _list: OrderReportsListItemType[] = []
  _total = 0
  _selected = 0
  _detail: OrderReportsListItemType | Record<string, any> = {}
  _exportOrderReports: TableExportType | Record<string, any> = {}
  _successfulCreate = false

  get orderReportsList() {
    return this._list
  }

  get orderReportsTotal() {
    return this._total
  }

  get selectedOrderReports() {
    return this._selected
  }

  get orderReportsDetail() {
    return this._detail
  }

  get exportOrderReports() {
    return this._exportOrderReports
  }

  get successfulCreate() {
    return this._successfulCreate
  }

  @MutationAction({
    mutate: ['_list', '_total', '_selected'],
  })
  async getOrderReportsList(payload: PayloadListType) {
    const [error, data] = await orderReportsAPI.orderReportsList(payload)

    if (!error && data) {
      return {
        _list: data.items,
        _total: data.count,
        _selected: data.SELECTED,
      }
    } else {
      return {
        _list: [],
        _total: 0,
        _selected: null,
      }
    }
  }

  @MutationAction({
    mutate: ['_detail'],
  })
  async getOrderReportsDetail(id: number) {
    const [error, data] = await orderReportsAPI.orderReportsDetail(id)

    if (!error && data) {
      return {
        _detail: data,
      }
    } else {
      return {
        _detail: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_exportOrderReports'],
  })
  async getExportOrderReports(payload: PayloadListType) {
    const [error, data] = await orderReportsAPI.orderReportsExport(payload)

    if (!error && data) {
      return {
        _exportOrderReports: data,
      }
    } else {
      return {
        _exportOrderReports: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_successfulCreate'],
  })
  async orderReportsCreate(data: OrderReportsCreateType) {
    const [error, response] = await orderReportsAPI.ordersReportsCreate(data)

    if (!error && response) {
      return {
        _successfulCreate: true,
      }
    } else {
      return {
        _successfulCreate: false,
      }
    }
  }
}
