import {
  Module,
  VuexModule,
  MutationAction,
  Action,
} from 'vuex-module-decorators'
import { userAPI } from '@/api/user.api'
import { UserResponseType } from '@/types/user'
import { logoutAPI } from '@/api/logout.api'

export type LoginBodyType = {
  login: string
  password: string
}

@Module({ stateFactory: true, namespaced: true })
export default class UserModule extends VuexModule {
  _user: UserResponseType | Record<string, any> = {}
  _authorized = false

  get user() {
    return this._user
  }

  get authorized() {
    return this._authorized
  }

  @MutationAction({
    mutate: ['_authorized'],
  })
  async login(data: LoginBodyType) {
    const [error, _] = await userAPI.loginUser(data.login, data.password)
    if (!error) {
      return {
        _authorized: true,
      }
    } else {
      return {
        _authorized: false,
      }
    }
  }

  @MutationAction({
    mutate: ['_authorized'],
  })
  async logout() {
    await logoutAPI.logout()
    return {
      _authorized: false,
    }
  }

  @MutationAction({
    mutate: ['_authorized'],
  })
  async getSelfData() {
    const [error, _] = await userAPI.selfUser()

    if (!error) {
      return {
        _authorized: true,
      }
    } else {
      return {
        _authorized: false,
      }
    }
  }

  @MutationAction({
    mutate: ['_user'],
  })
  async getUserData() {
    const [error, response] = await userAPI.user()

    if (!error && response) {
      return {
        _user: response,
      }
    } else {
      return {
        _user: {},
      }
    }
  }
}
