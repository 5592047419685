import { Module, VuexModule, MutationAction } from 'vuex-module-decorators'
import { membersAPI } from '@/api/members.api'
import { MembersListItemType } from '@/types/members'
import {
  PayloadListType,
  PayloadListWithQueryType,
  TableExportType,
} from '@/types/general'

@Module({ stateFactory: true, namespaced: true })
export default class MembersModule extends VuexModule {
  _list: MembersListItemType[] = []
  _total = 0
  _selected = 0
  _detail: MembersListItemType | Record<string, any> = {}
  _exportMembers: TableExportType | Record<string, any> = {}
  _exportMember: TableExportType | Record<string, any> = {}

  get membersList() {
    return this._list
  }

  get membersTotal() {
    return this._total
  }

  get selectedMembers() {
    return this._selected
  }

  get membersDetail() {
    return this._detail
  }

  get exportMembers() {
    return this._exportMembers
  }

  get exportMember() {
    return this._exportMember
  }

  @MutationAction({
    mutate: ['_list', '_total', '_selected'],
  })
  async getMembersList(payload?: PayloadListWithQueryType) {
    const [error, data] = await membersAPI.membersList(
      payload?.params?.limit,
      payload?.params?.offset,
      payload?.params?.filter,
      payload?.params?.order,
      payload?.query?.isTop,
      payload?.query?.topLimit
    )

    if (!error && data) {
      return {
        _list: data.items.map((member) => {
          return {
            ...member,
            ACTIVE_BALANCE: parseInt(member.ACTIVE_BALANCE),
          }
        }),
        _total: data.count,
        _selected: data.SELECTED,
      }
    } else {
      return {
        _list: [],
        _total: 0,
        _selected: null,
      }
    }
  }

  @MutationAction({
    mutate: ['_detail'],
  })
  async getMembersDetail(id: number) {
    const [error, data] = await membersAPI.membersDetail(id)

    if (!error && data) {
      return {
        _detail: data,
      }
    } else {
      return {
        _detail: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_exportMembers'],
  })
  async getExportMembers(payload?: PayloadListType) {
    const [error, data] = await membersAPI.membersExport(
      payload?.filter,
      payload?.order,
      payload?.query?.isTop,
      payload?.query?.topLimit
    )

    if (!error && data) {
      return {
        _exportMembers: data,
      }
    } else {
      return {
        _exportMembers: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_exportMember'],
  })
  async getExportMember(id: number) {
    const [error, data] = await membersAPI.memberExport(id)

    if (!error && data) {
      return {
        _exportMember: data,
      }
    } else {
      return {
        _exportMember: {},
      }
    }
  }
}
