import {
  Module,
  VuexModule,
  MutationAction,
  Action,
} from 'vuex-module-decorators'
import { vendorsAPI } from '@/api/vendors.api'
import {
  VendorOptionsType,
  VendorsCreateType,
  VendorsListItemType,
} from '@/types/vendors'
import {
  PayloadListType,
  PayloadListWithQueryType,
  TableExportType,
} from '@/types/general'

@Module({ stateFactory: true, namespaced: true })
export default class VendorsModule extends VuexModule {
  _list: VendorsListItemType[] = []
  _total = 0
  _selected = 0
  _detail: VendorsListItemType | Record<string, any> = {}
  _exportVendors: TableExportType | Record<string, any> = {}
  _createResponse = ''
  _allVendors: VendorOptionsType[] = []
  _topUpBalance = ''

  get vendorsList() {
    return this._list
  }
  get allVendors() {
    return this._allVendors
  }

  get vendorsTotal() {
    return this._total
  }

  get selectedVendors() {
    return this._selected
  }

  get vendorsDetail() {
    return this._detail
  }

  get exportVendors() {
    return this._exportVendors
  }

  get createResponse() {
    return this._createResponse
  }

  get topUpBalance() {
    return this._topUpBalance
  }

  @MutationAction({
    mutate: ['_list', '_total', '_selected'],
  })
  async getVendorsList(payload?: PayloadListWithQueryType) {
    const [error, data] = await vendorsAPI.vendorsList(
      payload?.params?.limit,
      payload?.params?.offset,
      payload?.params?.filter,
      payload?.params?.order,
      payload?.query?.isTop,
      payload?.query?.topLimit
    )

    if (!error && data) {
      return {
        _list: data.items,
        _total: data.count,
        _selected: data.SELECTED,
      }
    } else {
      return {
        _list: [],
        _total: 0,
        _selected: null,
      }
    }
  }

  @MutationAction({
    mutate: ['_detail'],
  })
  async getVendorsDetail(id: number) {
    const [error, data] = await vendorsAPI.vendorsDetail(id)

    if (!error && data) {
      return {
        _detail: data,
      }
    } else {
      return {
        _detail: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_exportVendors'],
  })
  async getExportVendors(payload?: PayloadListType) {
    const [error, data] = await vendorsAPI.vendorsExport(
      payload?.filter,
      payload?.order,
      payload?.query?.isTop,
      payload?.query?.topLimit
    )

    if (!error && data) {
      return {
        _exportVendors: data,
      }
    } else {
      return {
        _exportVendors: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_createResponse'],
  })
  async createVendors(data: VendorsCreateType) {
    const [error, response] = await vendorsAPI.vendorsCreate(data)

    if (!error && response) {
      return {
        _createResponse: response,
      }
    } else {
      return {
        _createResponse: error,
      }
    }
  }

  @MutationAction({
    mutate: ['_allVendors'],
  })
  async getAllVendors() {
    const [error, response] = await vendorsAPI.getAllVendors()
    if (!error && response) {
      return {
        _allVendors: response,
      }
    } else {
      return {
        _allVendors: [],
      }
    }
  }

  @MutationAction({
    mutate: ['_topUpBalance'],
  })
  async vendorUpdateBalance(payload: { id: string; text: string }) {
    const [error, response] = await vendorsAPI.vendorUpdateBalance(payload)

    if (!error && response) {
      return {
        _topUpBalance: response,
      }
    } else {
      return {
        _topUpBalance: '',
      }
    }
  }
}
