import {
  Module,
  VuexModule,
  MutationAction,
  Action,
} from 'vuex-module-decorators'
import { tasksAPI } from '@/api/tasks.api'
import { CreateTaskDataType, TasksItemListType } from '@/types/tasks'
import { CountListType, PayloadListType } from '@/types/general'

@Module({ stateFactory: true, namespaced: true })
export default class TasksModule extends VuexModule {
  _list: TasksItemListType[] = []
  _total = 0
  _selected = 0
  _count: CountListType[] = []
  _detail: TasksItemListType | Record<string, any> = {}
  _exportTasks: TasksItemListType | Record<string, any> = {}

  get tasksList() {
    return this._list
  }

  get tasksTotal() {
    return this._total
  }

  get tasksCount() {
    return this._count
  }

  get tasksDetail() {
    return this._detail
  }

  get exportTasks() {
    return this._exportTasks
  }

  @MutationAction({
    mutate: ['_list', '_total', '_selected'],
  })
  async getTasksList(payload: PayloadListType) {
    const [error, data] = await tasksAPI.tasksList(payload)

    if (!error && data) {
      return {
        _list: data.items,
        _total: data.count,
        _selected: data.SELECTED,
      }
    } else {
      return {
        _list: [],
        _total: 0,
        _selected: 0,
      }
    }
  }

  @Action
  async createTask(data: CreateTaskDataType) {
    await tasksAPI.tasksCreate(data)
  }

  @MutationAction({
    mutate: ['_count'],
  })
  async getTasksCount(payload: PayloadListType) {
    const [error, data] = await tasksAPI.tasksCount(payload)

    if (!error && data) {
      return {
        _count: data,
      }
    } else {
      return {
        _count: [],
      }
    }
  }

  @MutationAction({
    mutate: ['_detail'],
  })
  async getTasksDetail(id: number) {
    const [error, data] = await tasksAPI.tasksDetail(id)

    if (!error && data) {
      return {
        _detail: data,
      }
    } else {
      return {
        _detail: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_exportTasks'],
  })
  async getExportTasks(payload: PayloadListType) {
    const [error, data] = await tasksAPI.tasksExport(payload)

    if (!error && data) {
      return {
        _exportTasks: data,
      }
    } else {
      return {
        _exportTasks: {},
      }
    }
  }
}
